import * as React from "react";
import { css } from "@emotion/css";
import clsx from "clsx";

import {
  Block,
  Button,
  Contact,
  Page,
  Section,
  SubSection,
} from "../components";

import backgroundimg from "../images/backgroundimg.jpg";
import img01 from "../images/startup.png";
import img02 from "../images/line-chart.png";
import img03 from "../images/devices.png";
import img04 from "../images/chu-rouen-5.jpg";
import img05 from "../images/avicenne-1.jpg";
import img06 from "../images/marque_antadir_q.jpg";
import img07 from "../images/cak-1.png";
import img08 from "../images/logo_adir-2.png";
import app07 from "../images/app07.jpg";
import app08 from "../images/app08.jpg";
import app09 from "../images/app09.jpg";
import app10 from "../images/app10.jpg";
import app11 from "../images/app11.jpg";
import app12 from "../images/app12.jpg";
import Waves from "../assets/waveshape.svg";

import {
  h1Style,
  contentStyle,
  h2Style_w,
  h4Style,
  GreyBlock,
  threeColBlock,
  p2Style,
  p3Style,
  waveBlock,
  featureItem,
} from "../globalstyle";

const LandingTwo = () => {
  return (
    <Page tabTitle="Présentation">
      <Block className={GreyBlock}>
        <div className={contentStyle}>
          <h1 className={h1Style}>
            Gérez vos études cliniques en toute sérénité grâce à notre solution
            agile de data management
          </h1>
          <p className={p2Style}>
            <b>Dotter.science</b> est une plateforme en ligne permettant
            d’optimiser la conception, la saisie et le management électronique
            des protocoles de recherche clinique.
          </p>
          <p className={p2Style}>
            Destinée aux laboratoires, aux sociétés de biotechnologie et aux
            organismes pilotant des protocoles de recherche en santé,
            <b> Dotter.science </b>
            vous permet de gagner en temps et en traçabilité, tout en maîtrisant
            vos coûts.
          </p>
          <Button link="#get-demo" color="red">
            DÉCOUVRIR GRATUITEMENT
          </Button>
        </div>
      </Block>
      <Waves
        className={css`
          width: 100vw;
          height: auto;
          margin: 0;
        `}
      />
      <div className={waveBlock}>
        <img
          src={backgroundimg}
          className={css`
            position: fixed;
            z-index: -1;
            top: 0;
            height: 100vh;
            width: auto;
            @media screen and (max-width: 600px) {
              align-self: center;
              height: 100vh;
              width: auto;
            }
          `}
        />
      </div>
      <Section
        title="Les fonctionnalités Dotter.science"
        color="turquoise"
        pSize={4}
        callToAction={
          <Button link="/fonctionnalites" color="white">
            EN SAVOIR PLUS SUR LES FONCTIONNALITÉS
          </Button>
        }
        column
      >
        <div className={featureItem}>
          <img src={img01} />
          <div>
            <h5 className={h2Style_w}>Démarrez vos études à vitesse grand V</h5>
            <p className="content">
              Choisir un schéma d’étude, concevoir les formulaires de recueil,
              ajouter les intervenants de l’essai… L’interface moderne et
              conviviale de <b>Dotter.science</b> permet une prise en main
              rapide et un déploiement facilité au sein des différents centres
              investigateurs. Sur demande, nous configurons votre étude pour
              vous.
            </p>
          </div>
        </div>
        <div className={featureItem}>
          <img src={img02} />
          <div>
            <h5 className={h2Style_w}>Gérez les essais de façon centralisée</h5>
            <p className="content">
              <b>Dotter.science</b> vous permet de suivre le recrutement et vous
              notifie des queries et événements indésirables à tout moment, pour
              une réactivité optimale. Les outils de collaboration intégrés
              permettent de faciliter le travail de monitoring. Vous menez ainsi
              vos études dans les délais prévus et maîtrisez les coûts associés.
            </p>
          </div>
        </div>
        <div className={featureItem}>
          <img src={img03} />
          <div>
            <h5 className={h2Style_w}>Soyez assurés d’être bien entourés</h5>
            <p className="content">
              Notre plateforme web offre une expérience sécurisée et ergonomique
              sur n’importe quel appareil et respecte les normes en vigueur
              (RGPD, 21 CFR Part 11). L’interface utilisateur intuitive et la
              réactivité de nos équipes vous garantissent de faire aboutir vos
              projets de recherche clinique. Avec
              <b> Dotter.science</b>, travaillez en toute sérénité.
            </p>
          </div>
        </div>
      </Section>
      <Section title="Nos garanties" color="white" pSize={2} column>
        <div
          className={clsx(
            threeColBlock,
            css`
              width: 60vw;
              @media screen and (max-width: 600px) {
                width: 90vw;
              } ;
            `
          )}
        >
          <div
            className={css`
              text-align: left;
            `}
          >
            <h4 className={h4Style}>20%</h4>
            <p className="content">
              De réduction des coûts de la recherche clinique, et jusqu’à 95% de
              papier en moins.
            </p>
          </div>
          <div
            className={css`
              text-align: left;
            `}
          >
            <h4 className={h4Style}>5 jours</h4>
            <p className="content">
              Pour la conception et le déploiement d’un schéma d’étude simple.
            </p>
          </div>
          <div
            className={css`
              text-align: left;
            `}
          >
            <h4 className={h4Style}>Jusqu’à 4</h4>
            <p className="content">
              Sauvegardes de la base de données par jour sur des serveurs
              certifiés HDS.
            </p>
          </div>
        </div>
        <div
          className={css`
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
          `}
        >
          <img src={app07} />
          <img src={app08} />
          <img src={app09} />
          <img src={app10} />
          <img src={app11} />
          <img src={app12} />
        </div>
      </Section>
      <div id="get-demo"></div>
      <Section
        color="green"
        pSize={4}
        callToAction={
          <>
            <Button link="https://demo.dotter.science/" color="white">
              DEMANDER MON ACCÈS À LA DÉMO
            </Button>
            <Button link="/faq" color="white">
              CONSULTER LA FOIRE AUX QUESTIONS
            </Button>
          </>
        }
      >
        <SubSection title="La preuve par l’exemple !">
          <p className="content">
            Demandez dès aujourd’hui votre accès à une étude de démonstration.
            Vous verrez comment appréhender rapidement la collecte et la gestion
            des données sur une étude de test.
          </p>
          <p className="content">
            Pour toute autre question, n’hésitez pas à nous contacter ou à
            consulter notre FAQ !
          </p>
        </SubSection>
      </Section>
      <Section
        color="white"
        title="Ils utilisent Dotter.science pour leurs projets"
      >
        <div
          className={css`
            display: flex;
            width: 80vw;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            flex-wrap: wrap;
            margin: 10vh;
            & img {
              padding: 20px;
            }
          `}
        >
          <img src={img04} width="176" />
          <img src={img05} width="176" />
          <img src={img06} width="176" />
          <img src={img07} width="176" />
          <img src={img08} width="176" />
        </div>
      </Section>
      <Section color="white" title="Témoignages">
        <div className={threeColBlock}>
          <div>
            <p className="content">
              <em>
                Nous avons utilisé la solution Dotter.science pour la mise en
                place d’un eCRF dans le cadre de l’étude MUCOSIM. Très sérieuse
                et bienveillante, l’équipe a su acquérir notre entière confiance
                en répondant dans les délais à nos besoins grâce à son reporting
                de grande qualité.
              </em>
            </p>
            <p className="content">
              <b>Léa Mba Mintsa</b>
              <br />
              Fédération ANTADIR – Eval Clin
            </p>
          </div>
          <div>
            <p className="content">
              <em>
                La réactivité, la disponibilité et la force de proposition de
                l’équipe ont permis l’amélioration opérationnelle et pédagogique
                d’un eCRF interactif adapté à nos interlocuteurs. Nous sommes
                entièrement satisfaits de la prestation de la société KerNel
                Biomedical et envisageons de futures collaborations.
              </em>
            </p>
            <p className="content">
              <b>Jacqueline Delrieu</b>
              <br />
              Fédération ANTADIR – Eval Clin
            </p>
          </div>
          <div>
            <p className="content">
              <em>
                Ce qui me plaît avec Dotter.science, c’est qu’il est facile de
                créer ses formulaires et de compléter sa base de données via
                l’interface conviviale, permettant ainsi de la structurer pour
                en simplifier l’analyse.
              </em>
            </p>
            <p className={p3Style}>
              <b>Dr Maxime Patout</b>
              <br />
              Pneumologue au CHU de Rouen
            </p>
          </div>
        </div>
      </Section>

      <Contact />
    </Page>
  );
};

export default LandingTwo;
